.task-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .task-modal-content {
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .task-input, .task-textarea, .add-discussion-btn, .add-btn, .save-btn, .cancel-btn {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .task-textarea {
    resize: vertical; /* Allow vertical resizing */
  }
  
  .discussion-section {
    background-color: #f7f7f7;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .discussion-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .add-discussion-btn, .add-btn, .save-btn, .cancel-btn {
    cursor: pointer;
    background-color: #fe5f00 ;
    color: white;
    border: none;
  }
  
  .add-discussion-btn:hover, .add-btn:hover, .save-btn:hover, .cancel-btn:hover {
    background-color: #e75500;
  }
  
  .cancel-btn {
    background-color: #fcb07e ; /* Differentiate cancel button */
  }
  
  .cancel-btn:hover {
    background-color: #faa769;
  }

  .add-discussion-link {
    color: #007bff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .add-discussion-link:hover {
    text-decoration: underline;
  }

  .discussion-text {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .discussion-meta {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
  
  .discussion-author {
    font-weight: bold;
  }
  
  .discussion-time {
    color: #666;
  }

  .date-conatiner {
    display: flex;
    justify-content: start;
    gap: 20px;
  }