.user-profile-container {
    position: relative;
    display: inline-block;
    padding-right: 10px;
  }
  
  .profile-avatar {
    cursor: pointer;
    width: 60px; /* Adjust based on your preference */
    height: 60px; /* Adjust to maintain aspect ratio */
    border-radius: 50%; /* Circular avatar */
    object-fit: cover; /* Ensure the image covers the area without distortion */
  }
  
  .dropdown-menu {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 4px;
  }
  
  .dropdown-menu a, .dropdown-menu div {
    padding: 10px 0;
    text-decoration: none;
    display: block;
    color: black;
    cursor: pointer;
  }
  
  .dropdown-menu a:hover, .dropdown-menu div:hover {
    background-color: #f1f1f1;
  }

  .login-button {
    background-color: #fe5f00; /* Primary color */
    color: #fdead9; /* Secondary color */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-button svg {
    margin-right: 8px;
  }
  