.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    box-shadow: 5px 10px 30px #ff4a0361;
    background-color: #ffe5d6;
  }
  
  .login-container h2 {
    margin-bottom: 20px;
  }
  
  .login-container input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-container button {
    width: 100%;
    padding: 10px;
    background-color: #fe5f00;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #fe5f00;
  }

  .login-container button:disabled {
    opacity: .65;
    pointer-events: none;
  }
  
  .login-background {
    background-image: url('../images/eco-crm-landin-page.png'); /* Adjust the path as necessary */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1; /* Ensure the background is behind other content */
}

  /* Responsive CSS */
  @media (max-width: 768px) {
    .login-container {
      max-width: 80%;
    }
  }
  
  @media (max-width: 576px) {
    .login-container {
      max-width: 90%;
    }
  }
  