.task-detail-container {
    display: flex;
    flex-direction: column;
  }
  
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .save-btn, .cancel-btn {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    width: auto
  }
  
  .save-btn {
    background-color: #fe5f00 ;; /* Green */
    color: white;
  }
  
  .cancel-btn {
    background-color: #fcb07e; /* Red */
    color: white;
  }
  
  .save-btn:hover {
    background-color: #e75500;
  }

  .cancel-btn:hover {
    background-color: #fa9d5e;
  }

  .tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 3px solid transparent; /* For inactive tabs */
  }
  
  .tab.active {
    border-bottom: 3px solid #e75500; /* Active tab indicator */
    background-color: #fa9d5e;
  }
  
  .details-view {
    display: flex;
    margin-top: 20px;
  }
  
  .left-section {
    flex: 70%;
  }
  
  .right-section {
    flex: 30%;
    display: flex;
    flex-direction: column;
  }
  
  .attachments-view {
    margin-top: 20px;
  }
  
  .right-section {
    margin-left: 20px; /* Spacing from the left section */
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .linked-orders, .payment-history {
    padding: 10px;
    background-color: #f5f5f5; /* Light gray background for distinction */
    border-radius: 4px;
  }
  
  .linked-orders-headers {
    display: flex;
    align-items: center;
  }
  
  .linked-orders h3 {
    flex-grow: 1;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .orderIcon {
    margin-left: 15px;
    cursor: pointer;
    color: #fe5f00; /* A pleasant blue, adjust as needed */
  }
  
  .orderIcon:hover {
    color: #e75500; /* A darker shade of blue for hover effect */
  }
  
  /* Additional styling for the icons if you are using custom SVGs or images */
  .orderIcon svg {
    fill: currentColor; /* Allows the SVG to inherit the color of its parent */
    width: 24px; /* Adjust based on your preference */
    height: 24px; /* Adjust based on your preference */
  }

  .order-link {
    display: block; /* Ensure each order link is on a new line */
    margin-bottom: 5px;
    color: #007bff; /* Link color */
    text-decoration: none;
  }
  
  .order-link:hover {
    text-decoration: underline;
  }
  
  .payment-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .assigned-container {
    display: flex;
    justify-content: start;
    gap: 20px; /* Adjust the space between the two inputs */
    margin: 5px 0;
  }
  
  .client-input {
    display: flex;
    flex-direction: column;
  }
  
  .client-input label {
    margin-bottom: 5px;
  }
  
  .client-input input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px; /* Adjust based on your design requirements */
    background-color: #f9f9f9; /* Light gray background for read-only input */
  }
  
  .client-input input[readonly] {
    cursor: not-allowed; /* Indicates that the input is not editable */
    color: #6c757d; /* Darker text color for read-only input */
  }

  .assigned-container select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
  }

  .action-buttons button:disabled {
    opacity: .65;
    pointer-events: none;
  }

  .payment-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .view-link {
    cursor: pointer;
    color: #0000ff; /* or your theme's color */
    background: none;
    border: none;
    text-decoration: underline;
    padding: 0;
  }
  
  .view-link.disabled {
    color: #cccccc; /* greyed out */
    cursor: not-allowed;
    text-decoration: none;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .details-view, .right-section {
      flex-direction: column;
    }
  
    .left-section, .right-section {
      flex: none;
      width: 100%;
    }

    .right-section {
      margin-left: 0;
      margin-top: 20px;
    }

    .assigned-container {
      flex-direction: column;
    }
  
    .client-input input {
      width: 100%; /* Full width on smaller screens */
    }
  }
  