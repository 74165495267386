.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* The loader will take the full height of the viewport */
    width: 90vw;  /* The loader will take the full width of the viewport */
  }
  
  .loader {
    width: 20vw;  /* Adjust the width as a percentage of the viewport width */
    max-width: 150px; /* Additionally, you can set a max-width to ensure it doesn't get too big */
    height: auto; /* This will ensure the aspect ratio is maintained */
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  