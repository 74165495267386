.sideMenu {
    width: 50px; /* Initial width with only icons visible */
    background-color: #fdead9;
    height: 100vh;
    transition: width 0.5s;
    /*overflow-x: hidden;  Keep text hidden until expanded */
  }
  
  .sideMenu:hover {
    width: 200px; /* Expand width on hover */
  }
  
  .sideMenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sideMenu li a {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    padding: 10px;
  }
  
  .icon {
    margin-right: 10px; /* Space between icon and label */
  }
  
  .label {
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .sideMenu:hover .label {
    opacity: 1; /* Show label text on hover */
  }

  .sideMenu li a {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    padding: 10px;
    margin-bottom: 1px; /* Adds some space between menu items */
    /*background-color: #fe5f00;  Background color for the button */
    border-radius: 5px; /* Optional: rounds the corners of the buttons */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  /* Styling for the icons */
  .sideMenu li a .icon {
    font-size: 24px; /* Adjust the size as needed */
    margin-right: 10px; /* Space between icon and label */
  }
  
  /* Hover effect for buttons */
  .sideMenu li a:hover {
    background-color: #fe5f00; /* Darker background on hover */
  }
  
  /* Active effect for buttons */
  .sideMenu li a:active {
    background-color: #fe5f00; /* Darker background on hover */
    color: white !important;
  }

  /* Adjust label visibility */
  .label {
    display: block; /* Ensure the label is always visible */
    transition: opacity 0.3s; /* Smooth transition for the label */
    font-size: 16px; /* Adjust label font size as desired */
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .logo-container .label {
    display: inline-block;
    padding: 5px 10px; /* Add padding around the label */
    color: #fe5f00; /* Text color for the label */
    font-size: 20px; /* Font size for the label */
    font-weight: bold; /* Make the text bold */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-img {
    width: 50px;
  }