.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle span {
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
  }
  
  .dropdown-menu {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-menu li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background-color: #f1f1f1;
  }
  
  @media (max-width: 600px) {
    .dropdown-menu {
      width: 100%; /* Full-width on small screens */
    }
  }
  