.tasksContainer {
    max-width: 100%; /* Ensures the container does not exceed the width of its parent */
    overflow-x: auto; /* Allows horizontal scrolling within the documents container */
  }
    
  table {
    width: 100%; /* Ensures the table is the width of its container */
    border-collapse: collapse; /* Collapses the borders */
    /*table-layout: fixed;  Fixes the table layout */
  }
    
  th, td {
    border: 1px solid #ddd; /* Adds border for separation */
    text-align: left;
    padding: 8px;
  }
    
    /* These widths are examples; adjust them as needed for your content */
    th:nth-child(1) { width: 20%; } /* name column */
    th:nth-child(2) { width: 20%; } /* description column */
    th:nth-child(3) { width: 10%; } /* createdDate column */
    th:nth-child(4) { width: 10%; } /* deadlineDate column */
    th:nth-child(5) { width: 10%; } /* dueDate column */
    th:nth-child(6) { width: 10%; } /* status column */
    th:nth-child(7) { width: 10%; } /* progress column */
    th:nth-child(8) { width: 10%; } /* actions column */
    
    tr:hover {
      background-color: #f5f5f5; /* Adds a hover effect for table rows */
    }
    
    /* Icons styling */
    .fi {
      display: inline-block;
      margin-right: 8px; /* Space between the icon and the text */
    }
    
    .sort-asc::after {
      content: ' 🔼';
    }
    
    .sort-desc::after {
      content: ' 🔽';
    }
  
    .searchInput {
      padding: 8px;
      margin: 8px 0;
      border: 1px solid #ddd;
      border-radius: 4px;
      width: 100%; /* Adjust the width as per requirement, subtracting the total padding */
      box-sizing: border-box; /* Ensures padding does not affect the final width */
    }
    
    /* You can add a focus style to make it even more noticeable when active */
    .searchInput:focus {
      outline: none;
      border-color: #fe5f00; /* Use your theme's vivid orange color */
      box-shadow: 0 0 0 2px rgba(254, 95, 0, 0.2); /* Optional: Adds a glow effect */
    }
  
    .hyperlink:hover {
      text-decoration: underline;
      color: #fe5f00;
    }

    .hyperlink {
      display: flex;
      align-items: center; /* Aligns the icon vertically with the text */
      text-decoration: none; /* Removes underline from links */
      color: inherit; /* Inherits text color from parent */
      gap: 0.5em; /* Provides some space between the icon and the text */
    }
    
    .hyperlink svg {
      height: 1em; /* Matches the icon size to the font size of the text */
      width: 1em; /* Matches the icon size to the font size of the text */
    }

    /* Responsive adjustments */
    @media screen and (max-width: 768px) {
      th, td {
        display: block;
        width: 100%;
      }
    
      th {
        position: absolute;
        left: -9999px;
      }
    
      td {
        border: none;
        position: relative;
        padding-left: 50%; /* Adjust the percentage based on your design */
      }
    
      td:before {
        content: attr(data-label); /* Uses the data-label attribute for content */
        position: absolute;
        left: 0;
        width: 50%; /* Same as padding-left in td */
        padding-left: 15px; /* Adjust the padding */
        font-weight: bold;
        text-align: left;
      }
    }
    