.documentsContainer {
  max-width: 100%; /* Ensures the container does not exceed the width of its parent */
  overflow-x: auto; /* Allows horizontal scrolling within the documents container */
}

.add-doc-container {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  margin-bottom: 10px; /* Spacing between button and search input */
}

.add-doc-btn {
  display: inline-flex; /* Use flexbox to align items inline */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally (if there's extra space) */
  gap: 5px; /* Add some space between the icon and the text */
  padding: 10px 15px; /* Adjusted padding for better spacing */
  cursor: pointer;
  background-color: #fe5f00;
  color: #ffffff;
  font-size: 0.9rem;
  letter-spacing: 1px;
  border: none; /* Assuming you don't want a border, add if needed */
  border-radius: 3px;
  text-decoration: none; /* Remove underline from links if button is styled <a> tag */
  outline: none; /* Remove focus outline, consider keeping for accessibility */
}

.access-list-btn {
  display: inline-flex; /* Use flexbox to align items inline */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally (if there's extra space) */
  gap: 3px; /* Add some space between the icon and the text */
  padding: 5px 10px; /* Adjusted padding for better spacing */
  cursor: pointer;
  background-color: #fe5f00;
  color: #ffffff;
  font-size: 0.9rem;
  border-radius: 3px;
  border: none; /* Remove default border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for 3D effect */
  transition: all 0.2s ease-in-out; /* Smooth transition for hover effect */
  cursor: pointer;
}

.access-list-btn:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
  transform: translateY(-2px); /* Raise the button slightly on hover */
}

.access-list-btn:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow when active */
  transform: translateY(1px); /* Depress the button slightly when active */
}

.add-doc-btn svg {
  margin-right: 5px; /* Space between icon and text */
}

table {
  width: 100%; /* Ensures the table is the width of its container */
  border-collapse: collapse; /* Collapses the borders */
  /*table-layout: fixed;  Fixes the table layout */
}
  
th, td {
  border: 1px solid #ddd; /* Adds border for separation */
  text-align: left;
  padding: 8px;
}
  
  /* These widths are examples; adjust them as needed for your content */
  th:nth-child(1) { width: 30%; } /* Name column */
  th:nth-child(2) { width: 20%; } /* Upload Date column */
  th:nth-child(3) { width: 20%; } /* Uploaded By column */
  th:nth-child(4) { width: 20%; } /* Notes column */
  th:nth-child(5) { width: 10%; } /* Actions column */
  
  tr:hover {
    background-color: #f5f5f5; /* Adds a hover effect for table rows */
  }
  
  /* Icons styling */
  .fi {
    display: inline-block;
    margin-right: 8px; /* Space between the icon and the text */
  }
  
  .sort-asc::after {
    content: ' 🔼';
  }
  
  .sort-desc::after {
    content: ' 🔽';
  }

  .searchInput {
    padding: 8px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%; /* Adjust the width as per requirement, subtracting the total padding */
    box-sizing: border-box; /* Ensures padding does not affect the final width */
  }
  
  /* You can add a focus style to make it even more noticeable when active */
  .searchInput:focus {
    outline: none;
    border-color: #fe5f00; /* Use your theme's vivid orange color */
    box-shadow: 0 0 0 2px rgba(254, 95, 0, 0.2); /* Optional: Adds a glow effect */
  }

  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    th, td {
      display: block;
      width: 100%;
    }
  
    th {
      position: absolute;
      left: -9999px;
    }
  
    td {
      border: none;
      position: relative;
      padding-left: 50%; /* Adjust the percentage based on your design */
    }
  
    td:before {
      content: attr(data-label); /* Uses the data-label attribute for content */
      position: absolute;
      left: 0;
      width: 50%; /* Same as padding-left in td */
      padding-left: 15px; /* Adjust the padding */
      font-weight: bold;
      text-align: left;
    }
  }
  