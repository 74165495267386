@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.appBody {
  margin: 0px;
  /* padding-top: 80px; */
  background-color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
}

input {
  outline: none;
  border: none;
}

.logo img {
  width: 80px;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}

nav.active {
  box-shadow: 5px 10px 30px #ff4a0361;
  background-color: #ffffff;
}

nav ul {
  margin-left: auto;
}

.active {
  background-color: #fe5f00;
  color: #ffffff !important;
  box-shadow: 5px 10px 30px #ff4a0361;
  transition: all ease 0.2s;
}

nav ul li a {
  font-family: calibri;
  height: 40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #000000;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}

nav ul li a:hover {
  background-color: #fe5f00;
  color: #ffffff;
  box-shadow: 5px 10px 30px #ff4a0361;
  transition: all ease 0.2s;
}

nav .menu-btn,
.menu-icon {
  display: none;
}

.header-btns {
  display: flex;
  margin-top: 40px;
}

.cv-btn {
  width: 210px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fe5f00;
  box-shadow: 5px 10px 30px #ff4a0361;
  border-radius: 10px;
  color: #ffffff;
}

.cv-btn:hover {
  background-color: #fe5f00;
  transition: all ease 0.5s;
  color: #ffffff;
}

.f-heading h1 {
  color: #FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}

.f-heading p {
  color: rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}

.f-heading {
  text-align: center;
  margin-top: 60px;
  margin-bottom: -80px;
}

#contact {
  background-color: #ffe5d6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#contact h3 {
  font-size: 2.5rem;
  margin: 20px;
  color: #fff;
}

.contact-input {
  width: 400px;
  height: 50px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
}

.contact-input input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  color: #242425;
}

.contact-input a {
  width: 200px;
  height: 35px;
  background-color: #fe5f00;
  color: #FFFFFF;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 20px;
  font-weight: 500;
  transition: 0.4s ease-out;
}

.contact-input a:hover {
  background-color: #0099ff;
}

.contactuscontainer {
  display: flex;
  flex-direction: row;
}

.footer {
  width: 100%;
}

.footer-right {
  float: inline-end;
  margin-right: 2%;
}

.footer-cntent {
  padding-left: 8px;
}

.social-container {
  padding: 5px 10px;
  align-self: flex-start;
  max-width: 200px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

.mail-contact {
  padding: 5px 10px;
  flex: auto;
  flex-direction: column;
  align-self: flex-end;
}

.content {
  flex-grow: 1;
  padding: 20px;
  padding-top: 80px;
  overflow-y: auto; /* Adjust as needed */
}

.date-fields-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-field {
  display: flex;
  flex-direction: column;
}

.date-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  background-color: white;
}

.date-picker-input {
  border: none;
  padding: 5px;
  width: 100%;
  outline: none; /* Removes the outline to make it blend with the container */
}

.calendar-icon {
  margin-right: 5px;
  color: #fe5f00; /* Your primary theme color for consistency */
}

/* Adjust the size of the icons if necessary */
.calendar-icon svg {
  width: 20px;
  height: 20px;
}

/* Labels */
.modal-content label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333; /* Or any color that matches your theme */
}

/* Input and Textarea (including React DatePicker input) */
.modal-content .task-input,
.modal-content .task-textarea {
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Use your preferred font */
  font-size: 16px;
  color: #333;
}

/* Placeholder text style */
::placeholder {
  color: #aaa; /* Lighter text for placeholders */
  opacity: 1; /* Firefox */
}

@media(max-width:1190px) {
  
}

@media(max-width:970px) {
  
}

@media(max-width:600px) {
  .App {
    width: auto;
  }
  .date-fields-container {
    flex-direction: column;
  }

  .date-field {
    width: 100%; /* Ensures full width on smaller screens */
    margin-bottom: 10px;
  }
  
}

.scroll {
  left: 42.5%;
}

@media(max-width:1100px) {
  .menu-icon {
    display: block;
  }

  nav {
    justify-content: space-between;
    height: 65px;
    padding: 0px 30px;
  }

  .logo img {
    width: 70px;
  }

  .header-btns {
    margin: 0;
  }

  .menu {
    display: none;
    position: absolute;
    top: 65px;
    left: 0px;
    background-color: #FFFFFF;
    border-bottom: 4px solid #1db096;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }

  .menu li {
    width: 100%;
  }

  nav .menu li a {
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 25px;
    border: 1px solid rgba(38, 38, 38, 0.03);
  }

  nav .menu-icon {
    cursor: pointer;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }

  nav .menu-icon .nav-icon {
    background-color: #333333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  nav .menu-icon .nav-icon:before,
  nav .menu-icon .nav-icon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }

  nav .menu-icon .nav-icon:before {
    top: 5px;
  }

  nav .menu-icon .nav-icon:after {
    top: -5px;
  }

  nav .menu-btn:checked~.menu-icon .nav-icon {
    background: transparent;
  }

  nav .menu-btn:checked~.menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
  }

  nav .menu-btn:checked~.menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
  }

  nav .menu-btn {
    display: none;
  }

  nav .menu-btn:checked~.menu {
    display: block;
  }
}

@media(max-width:400px) {
  .s-box {
    width: 100%;
    height: 400px;
  }
}