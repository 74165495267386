.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* Make sure the toast is above other elements */
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    background: gray; /* Default background */
    animation: slideIn 0.5s ease-out, fadeOut 3s ease-out 2.5s forwards;
  }
  
  .toast.success {
    background: green;
  }
  
  .toast.failure {
    background: red;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-50%) translateY(100px);
      opacity: 0;
    }
    to {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
  