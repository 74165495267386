.uploadBbtn {
    float: right;
    margin-top: 20px;
    margin-bottom: 20px;
}

.documents-table {
  width: 100%;
  border-collapse: collapse;
}

.documents-table th,
.documents-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left; /* Default alignment for text */
}

/* Specific styles for header and cells of action columns */
.documents-table .action-column {
  width: 1%; /* Minimize width */
  white-space: nowrap; /* Prevent text from wrapping */
  text-align: center; /* Center content */
}

.action-column-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure the flex container takes up the full cell width */
  height: 100%; /* Optional: if you want the container to take up the full cell height */
}

.icon-button {
  cursor: pointer;
  font-size: 1.2rem; /* Use rem for consistent scaling */
}

.icon-button.download {
  color: #fe5f00;
}

.icon-button.approve {
  color: #006100;
}

.icon-button.remove {
  color: red;
}

.no-data {
  position: fixed;  /* Fixed positioning relative to the viewport */
  top: 50%;         /* Center vertically */
  left: 50%;        /* Center horizontally */
  transform: translate(-50%, -50%);
  text-align: center; /* Center the text inside the div */
  font-size: 24px;  /* Larger font size for visibility */
  color: #666;      /* Stylish gray color for the text */
  font-family: Arial, sans-serif; /* More readable font style */
  padding: 20px;
  background-color: #fff; /* White background for better readability */
  border-radius: 10px; /* Rounded corners for a smoother look */
  box-sizing: border-box; /* Ensures padding does not affect the width */
}

.approved-text {
  font-size: 0.85em;  /* Slightly smaller font size */
  color: #28a745;  /* A vibrant green, similar to Bootstrap's success color */
  font-weight: bold;  /* Make the text bold */
  padding: 2px 4px;
  margin-left: 5px;  /* Space between the filename and approved label */
}

@media (max-width: 768px) {
  .documents-table {
    font-size: 0.8rem; /* Smaller font size for mobile */
  }

  .icon-button {
    font-size: 1rem; /* Smaller icons for mobile */
  }
}

  