.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  /* Keep other existing styles */
}

.close-button {
  position: absolute;
  top: -10px;
  /* Adjust so the button is partially outside the modal */
  right: -10px;
  /* Adjust so the button is partially outside the modal */
  font-size: 1.5rem;
  color: #aab8c2;
  cursor: pointer;
  background-color: white;
  /* Match the modal background or as preferred */
  border-radius: 50%;
  /* Circular shape */
  width: 30px;
  /* Fixed width */
  height: 30px;
  /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  /* Optional: adds a border that matches the modal's box-shadow color */
}

.modal-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.form-section,
.mapping-section {
  margin-bottom: 10px;
  margin-top: 30px;
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  width: 30%;
  min-width: 70px;
}

.form-group input,
.form-group select {
  width: 70%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn-save,
.btn-close {
  padding: 10px 20px;
  border: none;
  border-radius: 5px !important;
  cursor: pointer;
  font-weight: bold !important;
}

.btn-save {
  background-color: #fe5f00 !important;
  color: white !important;
}

.btn-close {
  background-color: #fcb07e !important;
  color: white !important;
}

.btn-save:hover {
  background-color: #e75500 !important;
}

.btn-close:hover {
  background-color: #fa9d5e !important;
}


.order-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.order-item label {
  margin-left: 5px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th,
.orders-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.orders-table th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
}

.orders-table tr:nth-child(odd) td {
  background-color: #f9f9f9;
}

.orders-table th.checkbox-column,
.orders-table td.checkbox-column {
  width: 1%;
  /* Assign a small width percentage to start with */
  white-space: nowrap;
  /* Ensures content doesn't cause the column to expand */
}

/* For the header, if you want the checkbox centered */
.orders-table th.checkbox-column {
  text-align: center;
}

.payment-options {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4; /* Light grey background */
  border-radius: 8px;
  gap: 20px; /* Space between radio options */
  margin-left: 15%;
}

.radio-option {
  display: flex;
  align-items: center; /* Center the label text with the custom radio button */
  font-size: 16px;
  color: #333; /* Dark grey text color */
  position: relative; /* For absolute positioning of pseudo-elements */
}

.radio-option input[type="radio"] {
  opacity: 0; /* Hide the default radio button */
  position: absolute; /* Take it out of the flow */
  cursor: pointer; /* To indicate an interactive element */
}

.radio-option label {
  cursor: pointer; /* Ensure label also triggers the input */
  padding-left: 25px; /* Make room for the custom radio button */
  width: 100%;
}

/* Custom radio button design */
.radio-option input[type="radio"] + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
}

/* Custom indicator for checked state */
.radio-option input[type="radio"]:checked + label::after {
  content: '';
  position: absolute;
  left: 2%;
  top: 54%;
  transform: translateY(-50%);
  height: 12px;
  width: 12px;
  background-color: #fe5f00; /* Your primary theme color */
  border-radius: 50%;
}

/* Label text styles */
.radio-option label {
  display: inline-block;
  margin: 0; /* Adjust or remove margin as needed */
}

/* Styling for when the input is focused (optional) */
.radio-option input[type="radio"]:focus + label::before {
  outline: 2px auto rgba(0,123,255,.5); /* Bootstrap style focus ring */
  outline-offset: 2px;
}


/* Responsive styling */
@media (max-width: 600px) {
  .modal-content {
    width: 95%;
    margin: 10% auto;
  }

  .modal-actions {
    flex-direction: column;
  }

  .modal-actions button {
    width: 100%;
    margin-top: 10px;
  }
  .switch-container {
    font-size: 18px; /* Larger text on larger screens */
}
}